import faunadb from 'faunadb'
import cookies from 'next-cookies'
import { useCookie } from 'next-cookie'
// import _ from 'lodash'
import { useEffect, useState } from 'react'

import dynamic from 'next/dynamic'
const PublicOnepager = dynamic(() => import('@/components/onepager/Public'), {
  ssr: false,
})
const PublicOnepagerPreview = dynamic(
  () => import('@/components/onepager/PublicPreview'),
  {
    ssr: false,
  }
)
import PublicMeta from '@/components/onepager/PublicMeta'
import NoOnepagerNotice from '@/components/app/NoOnepagerNotice'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
const SavedHistory = dynamic(
  () => import('@/components/onepager/SavedHistory'),
  {
    ssr: false,
  }
)

export default function Home({
  isTemplate,
  isCollection,
  slug,
  template,
  collection,
  isPreview,
  onepagerId,
}) {
  const router = useRouter()
  const [loggedInEmail, setLoggedInEmail] = useState(null)
  const [reloadSaveHistory, setReloadSaveHistory] = useState(0)

  useEffect(() => {
    if (Cookies.get('wl-email')) {
      setLoggedInEmail(Cookies.get('wl-email'))
    }
  }, [])

  //
  //  RENDER A TEMPLATE ( onepager )
  //
  if (isTemplate) {
    // NO TEMPLATE - show placeholder
    if (template == false) {
      return (
        <div className="bg-gray-50 dottedBG min-h-screen">
          <NoOnepagerNotice />
        </div>
      )
    } else {
      // HAS TEMPLATE - RENDER IT
      return (
        <div className="">
          {/* Meta info */}
          <PublicMeta
            template={template}
            collection={collection}
            isTemplate={isTemplate}
            isCollection={isCollection}
            slug={slug}
          />

          <div className="publicWrapper">
            {/* ONEPAGER VIEW */}
            {!isPreview && (
              <PublicOnepager
                template={template}
                collection={collection}
                isTemplate={isTemplate}
                isCollection={isCollection}
                hasSignedUp={() => {}}
                reloadSaveHistory={() => {
                  setReloadSaveHistory(reloadSaveHistory + 1)
                }}
              />
            )}
            {/* ONEPAGER PREVIEW (HIDE NAV, SIGNUP) */}
            {isPreview && (
              <PublicOnepagerPreview
                template={template}
                collection={collection}
                isTemplate={isTemplate}
                isCollection={isCollection}
              />
            )}
          </div>

          {loggedInEmail && (
            <div style={{ zIndex: 999999999, position: 'relative' }}>
              <SavedHistory
                email={loggedInEmail}
                onepagerId={onepagerId}
                reloadSaveHistory={reloadSaveHistory}
              />
            </div>
          )}
        </div>
      )
    }
  } else {
    // NOT LOGGED & APP.ONEPAGER
    return (
      <div className="bg-gray-50 dottedBG min-h-screen">
        <NoOnepagerNotice />
      </div>
    )
  }
}

export async function getServerSideProps(context) {
  let reqHost = context.req.headers.host
  reqHost = reqHost.split('.')
  const nxtCookie = useCookie(context)

  // local testing - add .com
  if (reqHost[1] == 'localhost:8181') {
    reqHost.push('.com')
  }

  // check if domain is not
  let domainhost = reqHost[1] + '.' + reqHost[2]
  let isCustomDomain = false

  if (
    domainhost == 'onepager.io' ||
    domainhost == 'onepager.app' ||
    domainhost == 'localhost:8181..com' ||
    domainhost == '899c-174-94-10-80.ngrok.io' ||
    domainhost == 'undefined.undefined'
  ) {
    isCustomDomain = false
  } else {
    isCustomDomain = true
  }

  // Get template by subdomain ( slug )
  if ((reqHost.length == 3 && reqHost[0] !== 'app') || isCustomDomain) {
    let subdomain = reqHost[0]
    let templateData = false
    let collectionData = false
    // let checkCustomDomain = context.req.headers.host
    // let getSubdomain = null
    let checkQueryString = context.query
    let isTemplate = false
    let isCollection = false
    let isPreview = false

    if (checkQueryString.i) {
      const q = faunadb.query
      const client = new faunadb.Client({
        timeout: 3000,
        secret: process.env.FAUNADB_SERVER_KEY,
      })

      // Check if this ID is a real onepager
      const getOnepager = await client
        .query(q.Get(q.Ref(q.Collection('Template'), `${checkQueryString.i}`)))
        .then(async function (ret) {
          // get collection info (logo, branding, etc)
          let getCollection = await client
            .query(q.Get(ret.data.collection))
            .then(async collectionReturn => {
              let collectionData = collectionReturn.data
              collectionData._id = collectionReturn.ref.id
              return collectionData
            })
            .catch(err => false)
          let getTemplate = ret.data
          getTemplate._id = ret.ref.id
          return {
            template: getTemplate,
            collection: getCollection,
          }
        })
        .catch(err => false)

      // if check --
      if (getOnepager) {
        // check again make sure templateData is return
        templateData = getOnepager.template
        templateData.collection = getOnepager.collection
        templateData.collection.subdomain = {}
        templateData.collection.user = {}
        templateData.user = {}
        collectionData = getOnepager.collection
        isTemplate = true
      } else {
        // not a real onepager --
      }
    }

    // check if autosignup is set
    if (checkQueryString.autosignup) {
      // create cookie!
      let email = decodeURIComponent(checkQueryString.autosignup)
      email = email.replace(/\s+/g, '+')
      email = email.toLowerCase()
      nxtCookie.set('wl-email', email, {
        expires: 14,
      })
    }

    if (checkQueryString.preview) {
      isPreview = true
    }

    return {
      props: {
        isTemplate: isTemplate,
        isCollection: isCollection,
        slug: subdomain,
        template: templateData,
        collection: collectionData,
        isPreview: isPreview,
        onepagerId: checkQueryString.i,
      },
    }
  } else {
    if (!cookies(context).authed) {
      // redirect to login
      const { res } = context
      res.setHeader('location', '/login')
      res.statusCode = 302
      res.end()
      return {
        props: {},
      }
    } else {
      const { res } = context
      res.setHeader('location', '/dashboard')
      res.statusCode = 302
      res.end()
      return {
        props: {},
      }
    }
  }
}
