import React, { useEffect } from 'react'
import Head from 'next/head'
import slugify from 'slugify'

function PublicMeta({ template, collection, isCollection, slug }) {
  if (isCollection) {
    // use collection meta

    return (
      <Head>
        <title>{collection.title}</title>
        <meta name="title" content={collection.title} />
        <meta name="description" content={collection.description} />
        {/* mobile */}
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          name="viewport"
          content="initial-scale=1, viewport-fit=cover, user-scalable=no"
        />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://${slug}.onepager.app/`} />
        <meta property="og:title" content={collection.title} />
        <meta property="og:description" content={collection.description} />
        {/* <!-- Twitter -- /> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://${slug}.onepager.app/`}
        />
        <meta property="twitter:title" content={collection.title} />
        <meta property="twitter:description" content={collection.description} />

        {/* IMAGES */}
        {/* check if has share_image */}
        {template.share_image && (
          <>
            <meta property="og:image" content={collection.cover} />
            <meta property="twitter:image" content={collection.cover} />
          </>
        )}
      </Head>
    )
  } else {
    // use template meta

    return (
      <Head>
        <title>{template.title}</title>
        <meta name="title" content={template.title} />
        <meta name="description" content={template.description} />
        {/* mobile */}
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta
          name="viewport"
          content="initial-scale=1, viewport-fit=cover, user-scalable=no"
        />
        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://${slug}.onepager.app/${slugify(template.title, {
            replacement: '-',
            remove: undefined,
            lower: true,
            strict: true,
          })}-${template._id}`}
        />
        <meta property="og:title" content={template.title} />
        <meta property="og:description" content={template.description} />
        {/* <!-- Twitter -- /> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://${slug}.onepager.app/`}
        />
        <meta property="twitter:title" content={template.title} />
        <meta property="twitter:description" content={template.description} />

        {/* IMAGES */}
        {/* no share image but has cover image */}
        {/* {!template.share_image && (
          <>
            {template.settings.media.thumbnail && (
              <>
                <meta
                  property="og:image"
                  content={template.settings.media.thumbnail}
                />
                <meta
                  property="twitter:image"
                  content={template.settings.media.thumbnail}
                />
              </>
            )}
          </>
        )} */}
        {/* check if has share_image */}
        {template.share_image && (
          <>
            <meta property="og:image" content={template.share_image} />
            <meta property="twitter:image" content={template.share_image} />
          </>
        )}

        {/* SEGMENT.IO */}
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("V4DXIqrnRlZ6pAZkY1g1VD9OfNRKeMZT");
          analytics.page();
          }}();
            `,
          }}
        /> */}
        {/* Simple Web Tracking */}
        <script
          async
          defer
          data-domain="onepager.app"
          src="https://plausible.io/js/plausible.js"
        ></script>
      </Head>
    )
  }
}

export default PublicMeta
